import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Blocks from '../components/blocks';
import WackyCategory from '../components/BlockLibrary/Wacky/WackyCategory';
import '../sass/main.scss';
import { titleCase } from '../components/utilities';

class WackyCategoryTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);

               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
               var trustbox = document.getElementsByClassName('trustpilot-widget');
               window.Trustpilot.loadFromElement(trustbox);
          };
     }

     render() {
          const category = get(this.props, 'data.contentfulWackyPetCategory');
          const path = get(this.props, '*');
          const page = get(this.props, 'data.contentfulWackyPetCategory');

          const getDefaultSeo = () => {
               const year = page.slug.split('-')[0];
               let petType = page.slug.split('-')[1];

               if (petType === 'exotic') {
                    petType = 'exotic pet';
               }

               const titleCasePetType = titleCase(petType);

               let defaultSeoTitle = `${year} Wacky ${titleCasePetType} Names | Top Unique & Funny ${titleCasePetType} Names of ${year}`;
               let defaultSeoMetaDescription = `Unleash the creativity with Nationwide's list of unique & funny ${petType} names from ${year}! View wacky pet name nominees from ${year} & find out who came in first.`;

               return { defaultSeoTitle, defaultSeoMetaDescription };
          }

          const { defaultSeoTitle, defaultSeoMetaDescription } = getDefaultSeo();

          return (
               <Layout>
                    <Seo
                         pageName={page.omniturePageNameLegacy || page.contentName}
                         specificPhone={page.specificPhone}
                         trackingCode={page.trackingCode}
                         title={category.seoTitleTag || defaultSeoTitle}
                         path={path}
                         canonical={category.seoCanonicalUrl ? category.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={category?.seoMetaDescription?.seoMetaDescription || defaultSeoMetaDescription}
                         robots={category.robotsDirective ? category.robotsDirective.robotsDirective : ''}
                         showAntiFlickerSnippet={category.showAntiFlickerSnippet}
                    />
                    <Header header={category.header}></Header>
                    <main id="main-content" role="main">
                         <WackyCategory category={category} />
                         {category.rows && <Blocks blocks={category.rows}></Blocks>}
                    </main>
                    <Footer footer={category.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};

const mapStateToProps = (state) => {
     return {
          state
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(WackyCategoryTemplate);

export const pageQuery = graphql`
     query WackyCategoryBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulWackyPetCategory(slug: { eq: $slug }) {
               ...ContentfulWackyPetCategoryFields
          }
     }
`;
